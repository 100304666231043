import React, { useState, useEffect, useRef, useReducer } from "react"
import ScrollBar from "react-perfect-scrollbar";
import AudioVisualizer from "./AudioVisualizer";
import Am from "../../assets/images/landingPage/svg/am.svg"
import Beatport from "../../assets/images/landingPage/svg/beatport_green.svg"
import Juno from "../../assets/images/landingPage/svg/juno.svg"
import Spotify from "../../assets/images/landingPage/svg/spotify.svg"
import Tidal from "../../assets/images/landingPage/svg/tidal.svg"
import Yt from "../../assets/images/landingPage/svg/yt.svg"
import ScSvg from "../../assets/images/landingPage/svg/Soundcloud.svg"
import DeezerSvg from "../../assets/images/landingPage/svg/deezer.svg"
import TraxsourceSvg from "../../assets/images/landingPage/svg/Traxsource.svg"
import AmazonMusicSvg from "../../assets/images/landingPage/svg/AmazonMusic.svg"

import Anghami from "../../assets/images/landingPage/svg/anghami.svg"
import Bandcamp from "../../assets/images/landingPage/svg/bandcamp.svg"
import Boomplay from "../../assets/images/landingPage/svg/boomplay.svg"
import iHeartRadio from "../../assets/images/landingPage/svg/iheartradio.svg"
import JioSaavn from "../../assets/images/landingPage/svg/jiosaavn.svg"
import SevenDigital from "../../assets/images/landingPage/svg/7digital.svg"

import PreviewLoader from "./previewLoader";

import PlayIcon from "./playerControlIcons/playIcon";
import ControlPlayIcon from "./playerControlIcons/controlPlayIcon";
import ControlPauseIcon from "./playerControlIcons/controlPauseIcon";
import ControlNextIcon from "./playerControlIcons/controlNextIcon";
import ControlPreviousIcon from "./playerControlIcons/controlPreviousIcon";

import TriangleIcon from "./playerControlIcons/triangleIcon";

import StoreLinkButtonPreview from "./storeLinkButtonPreview";
import calculateMiddleColor from "./helper"

import "./landingPagePreview.scss";

let gradientDegreeDict = {
    1: "180deg",
    2: "90deg",
    3: "135deg",
    4: "225deg",
    5: "270deg",
    6: "0deg"
};

var formatTime = function (time) {
	return [
		Math.floor((time % 3600) / 60),
		("00" + Math.floor(time % 60)).slice(-2),
	].join(":");
}

const fetchDuration = (path) => {
	return new Promise((resolve) => {
		const audio = new Audio();
		audio.src = path;
		audio.addEventListener("loadedmetadata", () => {
			resolve(formatTime(audio.duration));
		})
	})
}

const onButtonLink = (link) => {
    window.open(link, "_blank");
}

const initState = {
	currentMusic: null, //Index of current song
	musics: [], //Arr obj with: url, name...
	isPlayed: false, //Is player inintialized
	isPaused: false, //Is current song paused
	audioRef: null, //Audio ref
}

const reducer = (prevState, action) => {
	switch (action.type) {
		case "setData":
			return { ...prevState, ...action.data }
		case "initialize":
			action.data.audioRef.load();
			action.data.audioRef.play();
			return { ...prevState, ...action.data }
		case "changeSong":
			const nxtSong = prevState.currentMusic + action.direction;
			prevState.audioRef.load();
			prevState.audioRef.play();
			return { ...prevState, currentMusic: nxtSong, isPaused: false }
		case "playPause":
			if (prevState.isPaused) {
				prevState.audioRef.play();
			} else {
				prevState.audioRef.pause();
			}
			return { ...prevState, isPaused: !prevState.isPaused }
		case "itemSelect":
			prevState.audioRef.load()
			prevState.audioRef.play()
			return { ...prevState, isPaused: false, currentMusic: action.i }
        default:
			return { ...prevState, ...action.data }
	}
}


const LandingPagePreview = ({musicLinkData, previewFiles, previewFilesMissing, isloading}) => {

    const [state, dispatch] = useReducer(reducer, initState)

    const audioCtxRef = useRef(null);
    const audioAnalizerRef = useRef(null);
    const audioSourceRef = useRef(null);
    const audioRef = useRef(null)
	const timeRef = useRef(null)

    const [storeLinks, storeLinksSET] = useState({});
    const [isAudioSourceConnected, setIsAudioSourceConnected] = useState(false);

    let headerBgStyle = {background: musicLinkData.header_color}
    let triangleIconColor = musicLinkData.header_color;

    if(musicLinkData.header_color_scheme === 2 && musicLinkData.header_gradient) {
        let headerGradientDirection = gradientDegreeDict[musicLinkData.header_gradient.direction];

        headerBgStyle = {
            "background": musicLinkData.header_gradient.first_color,
            "background": `-moz-linear-gradient(${headerGradientDirection}, ${musicLinkData.header_gradient.first_color}, ${musicLinkData.header_gradient.second_color})`,
            "background": `-webkit-linear-gradient(${headerGradientDirection}, ${musicLinkData.header_gradient.first_color}, ${musicLinkData.header_gradient.second_color})`,
            "background": `linear-gradient(${headerGradientDirection}, ${musicLinkData.header_gradient.first_color}, ${musicLinkData.header_gradient.second_color}`,
            "filter": `progid:DXImageTransform.Microsoft.gradient(startColorstr="${musicLinkData.header_gradient.first_color}",endColorstr="${musicLinkData.header_gradient.second_color}",GradientType=1)`
        };
        if(headerGradientDirection === "0deg") {
            triangleIconColor = musicLinkData.header_gradient.first_color;
        }
        else if(headerGradientDirection === "180deg") {
            triangleIconColor = musicLinkData.header_gradient.second_color;
        }
        else {
            triangleIconColor = calculateMiddleColor(musicLinkData.header_gradient.first_color, musicLinkData.header_gradient.second_color, headerGradientDirection);
        }
    }

    const onEndHandler = () => {
		if (state.currentMusic === state.musics.length - 1) {
			dispatch({ type: "setData", data: { isPaused: true, currentMusic: 0 } })
		} else {
			onCntrlNext()
		}
	}

    const setupAudioContext = () => {
        if (!audioCtxRef.current) {
            audioCtxRef.current = new (window.AudioContext || window.webkitAudioContext)();
        }
    
        if (!audioAnalizerRef.current) {
            audioAnalizerRef.current = audioCtxRef.current.createAnalyser();
        }
    
        if (!audioSourceRef.current) {
            audioSourceRef.current = audioCtxRef.current.createMediaElementSource(audioRef.current);
            audioSourceRef.current.connect(audioAnalizerRef.current);
            audioAnalizerRef.current.connect(audioCtxRef.current.destination);
        }

        audioRef.current.ontimeupdate = (e) => {
            if (timeRef.current) {
                timeRef.current.innerText = formatTime(e.target.currentTime);
            }
        };
    
        audioRef.current.onloadedmetadata = (e) => {};
    };

    const cleanupAudioContext = () => {
        if (audioCtxRef.current) {
            audioCtxRef.current.close().then(() => {
                audioCtxRef.current = null;
                audioAnalizerRef.current = null;
                audioSourceRef.current = null;
                setIsAudioSourceConnected(false);
            });
        }
    };
    

    useEffect(() => {
        if(musicLinkData && musicLinkData.store_links) {
            let linksObj = {};
            musicLinkData.store_links.map((store_link) => {
                const storeKey = store_link.store.value;
                linksObj[`${storeKey}_url`] = store_link.link ? store_link.link : '';
            });
            storeLinksSET(linksObj);
        }
    }, [musicLinkData]);


    useEffect(() => {
        if (previewFiles && previewFiles.length > 0) {
            getDurations(previewFiles);
            dispatch({ type: "setData", data: { isPlayed: false, currentMusic: 0 } });
    
            if (audioRef.current) {
                cleanupAudioContext();
                setupAudioContext();
            }
        } else {
            cleanupAudioContext();
            dispatch({ type: "setData", data: { isPlayed: false, currentMusic: null, musics: [] } });
        }
    }, [previewFiles]);


    // Updates the displayed time
    useEffect(() => {
        if (audioRef.current !== null && timeRef.current !== null) {
            audioRef.current.ontimeupdate = (e) => {
                if (timeRef.current) {
                    timeRef.current.innerText = formatTime(e.target.currentTime);
                }
            };
            audioRef.current.onloadedmetadata = (e) => {};
        }
    }, []);
    
    const getDurations = async (previewFiles) => {
		const res = await Promise.all(
			previewFiles.map(({ url }) => fetchDuration(url))
		)
		dispatch({
			type: "setData",
			data: {
				musics: (() => previewFiles.map((m, i) => ({ ...m, length: res[i] })))(),
			},
		})
	}

    const onPlayClick = (event) => {
        if (state.musics.length > 0) {
            dispatch({
                type: "initialize",
                data: { currentMusic: 0, isPlayed: true, audioRef: audioRef.current },
            });
        }
        setupAudioContext();
        event.target.disabled = true;
    };

	//
	const onCntrlNext = () => {
		if (state.currentMusic < state.musics.length - 1) {
			dispatch({ type: "changeSong", direction: 1 })
		}
	}
	const onCntrlPrev = () => {
		if (state.currentMusic > 0) {
			dispatch({ type: "changeSong", direction: -1 })
		}
	}
	//
	const onPlayPauseClick = () => {
		dispatch({ type: "playPause" })
	}

	return (
        <div className="ml-lp-content">
        <div className="ml-lp-background-image" style={{
            backgroundImage: musicLinkData?.image_big? `url('${musicLinkData?.image_big}')` : '',
            WebkitTransition: 'background-image 1s ease-in-out',
            transition: 'background-image 1s ease-in-out',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            position: "absolute",
            borderRadius: "20px",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        }}>

        </div>
            <ScrollBar>
                <main className='bodymain'>
                    <div className='container mb-md-5 p-0'>
                        {
                            (!isloading && musicLinkData && musicLinkData.release_name) &&
                            <div className='row no-gutters'>
                                {
                                    !previewFilesMissing && 
                                    <div className='col-md-12 mt-2'>
                                    <center>
                                        
                                    </center>
                                    <figure className='playermain'>
                                        <div className='overlay' id='canvas-parent' />
                                        <img
                                            src={musicLinkData?.image_big}
                                            className='img-fluid audiobg'
                                            alt=''
                                            style={{
                                                ...(state.isPlayed && {
                                                    borderBottomLeftRadius: "0px",
                                                    borderBottomRightRadius: "0px",
                                                }),
                                            }}
                                        />
                                        {state.isPlayed === false && (
                                            <center>
                                                <div className='buttons'>
                                                    <PlayIcon fillColorOne={musicLinkData?.player_controls_color} fillColorTwo={"#ffffff"} onClick={onPlayClick} className="playicon" />
                                                </div>
                                            </center>
                                        )}

                                        {state.isPlayed && (
                                            <center>
                                                <div className='buttons'>
                                                    <ControlPreviousIcon fillColor={musicLinkData?.player_controls_color} onClick={onCntrlPrev} className="cleft" />
                                                    {state.isPaused && (
                                                        <ControlPlayIcon fillColor={musicLinkData?.player_controls_color} strokeColor={musicLinkData?.player_controls_color} onClick={onPlayPauseClick} className="playicon" />
                                                    )}
                                                    {state.isPaused === false && (
                                                        <ControlPauseIcon fillColor={musicLinkData?.player_controls_color} strokeColor={musicLinkData?.player_controls_color} onClick={onPlayPauseClick} className="playicon" />
                                                    )}
                                                    <ControlNextIcon fillColor={musicLinkData?.player_controls_color} onClick={onCntrlNext} className="cright" />
                                                </div>
                                            </center>
                                        )}

                                        <div className='time-container' style={{color: musicLinkData.player_controls_color}} ref={timeRef} />

                                        <div
                                            id='audio-canvas'
                                            style={{
                                                position: "absolute",
                                                bottom: "-6px",
                                                width: "100%",
                                            }}
                                        >
                                            <audio ref={audioRef} crossOrigin='' onEnded={onEndHandler}>
                                                <source
                                                    crossOrigin=''
                                                    type='audio/mp3'
                                                    src={state.musics[state.currentMusic]?.url}
                                                ></source>
                                            </audio>
                                        </div>
                                        {state.isPlayed && !state.isPaused ? (
                                            <AudioVisualizer
                                                audioAnalizer={audioAnalizerRef.current}
                                                canvasParenyId='canvas-parent'
                                            ></AudioVisualizer>
                                        ) : null}
                                    </figure>
                                    {state.isPlayed && (
                                        <div className='tablescroll'>
                                            <table className='table tracktable'>
                                                <thead>
                                                    <tr>
                                                        <th>Track</th>
                                                        <th>Artist</th>
                                                        <th>Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.musics.map((m, i) => {
                                                        return (
                                                            <tr
                                                                i={i}
                                                                className={`music-list-item ${
                                                                    state.currentMusic == i ? "music-list-item-active" : ""
                                                                }`}
                                                                onClick={() => {
                                                                    dispatch({ type: "itemSelect", i: i })
                                                                }}
                                                            >
                                                                <td style={{color: state.currentMusic === i ? musicLinkData?.player_controls_color: "#ffffff"}}>{m.artist_name}</td>
                                                                <td style={{color: state.currentMusic === i ? musicLinkData?.player_controls_color: "#ffffff"}}>{m.track_name}</td>
                                                                <td style={{color: state.currentMusic === i ? musicLinkData?.player_controls_color: "#ffffff"}}>{m?.length}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                                }

                                <div className='col-md-12'>
                                    <div className='card logocard'>
                                        <div className='card-header pb-3' style={{...headerBgStyle,}}>
                                            <h2 style={{color: musicLinkData.title_color?musicLinkData.title_color: "#ffffff"}}>{musicLinkData?.release_name}</h2>
                                            <p style={{color: musicLinkData.description_color?musicLinkData.description_color: "#ffffff"}}>{musicLinkData?.artist_name}</p>
                                            <p style={{color: musicLinkData.description_color?musicLinkData.description_color: "#ffffff"}} className='choose-music-text mt-2'>Choose Music Service</p>
                                            <TriangleIcon fillColor={triangleIconColor} className="triangle" />
                                        </div>
                                        <div className='card-body'>
                                            <ul className='logolist row row-cols-2 m-0 pl-0'>
                                                {storeLinks?.amazonmusic_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={AmazonMusicSvg} alt='' height={'100%'} width={110} />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.amazonmusic_url}
                                                            storeName={"amazonmusic"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.anghami_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={Anghami} alt='' height={'100%'} width={110} />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.anghami_url}
                                                            storeName={"anghami"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.applemusic_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={Am} alt='' />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.applemusic_url}
                                                            storeName={"applemusic"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.bandcamp_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={Bandcamp} alt='' height={'100%'} width={110} />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.bandcamp_url}
                                                            storeName={"bandcamp"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.beatport_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={Beatport} alt='' />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.beatport_url}
                                                            storeName={"beatport"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.boomplay_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={Boomplay} alt='' height={'100%'} width={110} />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.boomplay_url}
                                                            storeName={"boomplay"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.deezer_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={DeezerSvg} alt='' />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.deezer_url}
                                                            storeName={"deezer"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.iheartradio_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={iHeartRadio} alt='' height={'100%'} width={110} />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.iheartradio_url}
                                                            storeName={"iheartradio"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}                                            
                                                {storeLinks?.jiosaavn_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={JioSaavn} alt='' height={'100%'} width={110} />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.jiosaavn_url}
                                                            storeName={"jiosaavn"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.junodownload_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={Juno} alt='' />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.junodownload_url}
                                                            storeName={"junodownload"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.sevendigital_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={SevenDigital} alt='' height={'100%'} width={110} />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.sevendigital_url}
                                                            storeName={"sevendigital"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.soundcloud_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={ScSvg} alt='' />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.soundcloud_url}
                                                            storeName={"soundcloud"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.spotify_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={Spotify} alt='' />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.spotify_url}
                                                            storeName={"spotify"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.tidal_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={Tidal} alt='' />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.tidal_url}
                                                            storeName={"tidal"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.traxsource_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={TraxsourceSvg} alt='' />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.traxsource_url}
                                                            storeName={"traxsource"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}       
                                                {storeLinks?.youtube_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <img src={Yt} alt='' />
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.youtube_url}
                                                            storeName={"youtube"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                                {storeLinks?.other_url && (
                                                    <li>
                                                        <div className='brandlogo'>
                                                            <div className='empty'>Others</div>
                                                        </div>
                                                        <StoreLinkButtonPreview
                                                            storeLink={storeLinks?.other_url}
                                                            storeName={"other"}
                                                            musicLinkData={musicLinkData}
                                                            onButtonLink={onButtonLink}
                                                        />
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            isloading && <PreviewLoader show={isloading} />
                        }
                    </div>
                </main>
            </ScrollBar>
        </div>
	)
}

export default LandingPagePreview;