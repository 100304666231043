import React, { useContext, useState, useEffect, useRef } from "react";
import { StateContext } from "../utils/context";
import { getCookie } from "../jwt/_helpers";
import { useFetch } from "../utils/fetchHook";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;
const SUBUSER_ASAP_WIDGET_ID = process.env.REACT_APP_SUBUSER_ASAP_WIDGET_ID;

function generateNonce(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const SubUserZohoASAPWidget = () => {
  const { currentUser } = useContext(StateContext);
  const userToken = getCookie("token");
  const options = {
    method: "GET",
    mode: 'cors',
    headers: {
        Authorization: `Bearer ${userToken}`,
        "x-api-key": X_API_KEY,
        "Content-Type": "application/json"
    }
  };

  const [zohoUserID, zohoUserIDSET] = useState(false);
  const [zohoUserIDPresent, zohoUserIDPresentSET] = useState(null);
  const [forceUpdate, setForce] = useState(0);
  const didEff = useRef(false);

  const [zohoUser, error, isloading] = useFetch("GET", "zoho_update_user_details/", userToken, false, forceUpdate);

  useEffect(() => {
    if (!didEff.current) {
      didEff.current = true;
      return;
    }
    setForce(prev => prev + 1);
  }, [zohoUserIDPresent]);

  useEffect(() => {
    zohoUserIDPresentSET(currentUser && currentUser.zoho_id);
  }, [currentUser]);

  useEffect(() => {
    let zohoUserDetails = zohoUser["user"];
    if (zohoUserDetails && zohoUserDetails.id) {
      zohoUserIDSET(zohoUserDetails.id);
    }
  }, [zohoUser]);

  const getJwtTokenCallback = (successCallback, failureCallback) => {
    fetch(`${API_URL}zoho-jwt/`, options)
    .then(response => response.json())
    .then(data => {
      let zohoJWTToken = data.token;
      successCallback(zohoJWTToken);
    })
    .catch(error => {
      failureCallback(error);
    });
  }

  useEffect(() => {
    if (!currentUser) return;

    const scriptId = "zohodeskasapscript";

    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = scriptId;
      script.defer = true;
      script.nonce = generateNonce(16);
      script.src = `https://desk.zoho.eu/portal/api/web/asapApp/${SUBUSER_ASAP_WIDGET_ID}?orgId=20081914897`;

      script.onload = () => {
        console.log("Zoho Desk ASAP script loaded");

        window.ZohoDeskAsapReady = function (callback) {
          console.log("ZohoDeskAsapReady triggered");
          const readyQueue = (window.ZohoDeskAsap__asyncalls =
            window.ZohoDeskAsap__asyncalls || []);
          if (window.ZohoDeskAsapReadyStatus) {
            callback && readyQueue.push(callback);
            readyQueue.forEach((cb) => cb && cb());
            window.ZohoDeskAsap__asyncalls = null;
          } else {
            callback && readyQueue.push(callback);
          }
        };

        window.ZohoDeskAsapReady(() => {
          console.log("Callback executed from ZohoDeskAsapReady");
          if (window.ZohoDeskAsap) {
            window.ZohoDeskAsap.invoke("login", getJwtTokenCallback)
          }
        });
      };

      script.onerror = () => {
        console.error("Failed to load Zoho Desk ASAP script");
      };

      document.body.appendChild(script);
    } else {
      if (window.ZohoDeskAsapReady) {
        window.ZohoDeskAsapReady(() => {
          console.log("Callback executed from existing ZohoDeskAsapReady");
        });
      }
    }
  }, [currentUser, userToken]);

  return <div id={`zoho-user-${zohoUserID}`}></div>;
};

export default SubUserZohoASAPWidget;