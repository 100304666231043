import React, { useContext } from "react";
import { Col, Row, Button, Input } from "reactstrap";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SubUserFilter from "./subUserFilter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Link } from "react-router-dom";
import { StateContext } from "../utils/context";

const DisputeClaimsTable = ({ tableData, searchQuery, defaultSorted,   subUserData, }) => {
  const { currentUser } = useContext(StateContext);
  const { ExportCSVButton } = CSVExport;

  const releaseFormatter = (cell, row) => {
    const SRC = row.release_artwork ? row.release_artwork.thumb_small : null;
    return (
        <Link to={`/releases/${row.release}/view/`}>
            <div className="flexContainer">
                {SRC && <img style={{width: 40, height: 40, borderRadius: '100%'}} src={SRC} alt={cell} /> }
                <p className="fontWeight700">{cell}</p>
            </div>
        </Link>
    );
  };

  const releaseCsvFormatter = (cell) => cell;

  const statusFormatter = cell => {
    return (
      <>
        {cell === "created" ? (
          <Button className="btn-status-big" disabled color="primary">
            Created
          </Button>
        ) : cell === "pending" ? (
          <Button className="btn-status-big" disabled color="info">
            Pending
          </Button>
        ) : cell === "in_progress" ? (
          <Button className="btn-status-big" disabled color="warning">
            In Progress
          </Button>
        ) : cell === "resolved" ? (
          <Button className="btn-status-big" disabled color="success">
            Resolved
          </Button>
        ) : cell === "copyright_violation" ? (
          <Button className="btn-status-big"  disabled color="danger">
            Copyright Violation
          </Button>
        ): null}
      </>
    );
  };

  const statusCsvFormatter = cell => {
    if (cell === "created") {
      return "Created";
    } else if (cell === "pending") {
      return "Pending";
    } else if (cell === "in_progress") {
      return "In Progress";
    } else if (cell === "resolved") {
      return "Resolved";
    } else if (cell === "copyright_violation") {
      return "Copyright Violation";
    } else {
      return null;
    }
  };

  const columns = [
    {
        dataField: "release_name",
        text: "Release",
        formatter: releaseFormatter,
        csvFormatter: releaseCsvFormatter,
        sort: false,
        headerStyle: () => ({ width: "120px" })
    },
    {
      dataField: "release_owner",
      text: "User",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "130px" };
      }
    },
    {
        dataField: "store",
        text: "Store",
        sort: false,
        headerStyle: () => ({ width: "120px" })
    },
    {
        dataField: "claimant_name",
        text: "Claimant Name",
        sort: false,
        headerStyle: () => ({ width: "120px" })
    },
    {
        dataField: "claimant_email",
        text: "Claimant Email",
        sort: false,
        headerStyle: () => ({ width: "120px" })
    },
    {
        dataField: "resolution_time",
        text: "Resolution Time",
        sort: false,
        headerStyle: () => ({ width: "120px" })
    },
    {
        dataField: "time_remaining",
        text: "Remaining Time",
        sort: false,
        headerStyle: () => ({ width: "120px" })
    },
    {
        dataField: "status",
        text: "Status",
        formatter: statusFormatter,
        csvFormatter: statusCsvFormatter,
        sort: true,
        headerStyle: () => ({ width: "125px" })
    },
    {
      dataField: "release_name",
      sort: false,
      csvExport: false,
      hidden: true,
    }
  ];

  const handleTableChange = (type, { sortField, sortOrder }) => {
    const ascDesc = sortOrder === "desc" ? "-" : "";
    if (sortField === "status") {
      searchQuery.setOrdering({ order: ascDesc, name: sortField, sort: sortField });
    }
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={tableData}
      columns={columns}
      search={{
        searchFormatted: true
      }}
      exportCSV={{ fileName: "dispute-claims.csv" }}
    >
      {props => (
        <div>
          <Row>
            <Col>
              <div className="customSearch">
                <Input
                  placeholder="Search..."
                  value={searchQuery.query}
                  style={{ marginBottom: 10 }}
                  onChange={e => searchQuery.setQuery(e.target.value)}
                />
              </div>
            </Col>
            {
                currentUser.is_premium_user && <SubUserFilter subUserData={subUserData} />
            }
            <Col>
              <div className="flexEndContainer">
                <ExportCSVButton className="btn btn-outline-info" {...props.csvProps}>
                  Export CSV
                </ExportCSVButton>
              </div>
            </Col>
          </Row>
          <BootstrapTable
            {...props.baseProps}
            bordered={false}
            remote={{ sort: true }}
            defaultSorted={defaultSorted}
            onTableChange={handleTableChange}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default DisputeClaimsTable;