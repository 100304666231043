import React, { useEffect, useState, useRef, useReducer } from "react"
import Axios from "axios"
import Am from "../../assets/images/landingPage/svg/am.svg"
import Beatport from "../../assets/images/landingPage/svg/beatport_green.svg"
import Juno from "../../assets/images/landingPage/svg/juno.svg"
import Spotify from "../../assets/images/landingPage/svg/spotify.svg"
import Tidal from "../../assets/images/landingPage/svg/tidal.svg"
import Yt from "../../assets/images/landingPage/svg/yt.svg"
import ScSvg from "../../assets/images/landingPage/svg/Soundcloud.svg"
import DeezerSvg from "../../assets/images/landingPage/svg/deezer.svg"
import TraxsourceSvg from "../../assets/images/landingPage/svg/Traxsource.svg"
import AmazonMusicSvg from "../../assets/images/landingPage/svg/AmazonMusic.svg"

import Anghami from "../../assets/images/landingPage/svg/anghami.svg"
import Bandcamp from "../../assets/images/landingPage/svg/bandcamp.svg"
import Boomplay from "../../assets/images/landingPage/svg/boomplay.svg"
import iHeartRadio from "../../assets/images/landingPage/svg/iheartradio.svg"
import JioSaavn from "../../assets/images/landingPage/svg/jiosaavn.svg"
import SevenDigital from "../../assets/images/landingPage/svg/7digital.svg"

import TriangleIcon from "./playerControlIcons/triangleIcon"
import PlayIcon from "./playerControlIcons/playIcon"
import ControlNextIcon from "./playerControlIcons/controlNextIcon"
import ControlPreviousIcon from "./playerControlIcons/controlPreviousIcon"
import ControlPauseIcon from "./playerControlIcons/controlPauseIcon"
import ControlPlayIcon from "./playerControlIcons/controlPlayIcon"

import StoreLinkButton from "./storeLinkButton"

import ReactGA from "react-ga4"
import AudioVisualizer from "./AudioVisualizer"
import MetaTags from "react-meta-tags";

import PreviewLoader from "./previewLoader"
import calculateMiddleColor from "./helper"

import "./landingPage.scss"

const API_URL = process.env.REACT_APP_API_URL_BASE
const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID

var formatTime = function (time) {
	return [
		Math.floor((time % 3600) / 60), // minutes
		("00" + Math.floor(time % 60)).slice(-2), // seconds
	].join(":")
}

function fetchDuration(path) {
	return new Promise((resolve) => {
		const audio = new Audio()
		audio.src = path
		audio.addEventListener("loadedmetadata", () => {
			// To keep a promise maintainable, only do 1
			// asynchronous activity for each promise you make
			resolve(formatTime(audio.duration))
		})
	})
}


// STATE OBJECT
const initState = {
	currentMusic: null, //Index of current song
	musics: [], //Arr obj with: url, name...
	isPlayed: false, //Is player inintialized
	musicLink: "", //Arr obj for links
	isPaused: false, //Is current song paused
	audioRef: null, //Audio ref
}

let gradientDegreeDict = {
    1: "180deg",
    2: "90deg",
    3: "135deg",
    4: "225deg",
    5: "270deg",
    6: "0deg"
};

const reducer = (prevState, action) => {
	switch (action.type) {
		// Changes state data
		case "setData":
			return { ...prevState, ...action.data }
		// Initializes player:
		case "initialize":
			//SRC of first item of musics
			action.data.audioRef.load()
			action.data.audioRef.play() //Plays ref recived through action
			return { ...prevState, ...action.data } //Stores ref in state
		// Change songs:
		case "changeSong":
			const nxtSong = prevState.currentMusic + action.direction //Prev or next
			prevState.audioRef.load()
			prevState.audioRef.play()
			return { ...prevState, currentMusic: nxtSong, isPaused: false } //Update state
		//Play pause
		case "playPause":
			if (prevState.isPaused) {
				//If paused
				prevState.audioRef.play()
			} else {
				//If playing
				prevState.audioRef.pause()
			}
			return { ...prevState, isPaused: !prevState.isPaused }
		//
		case "itemSelect":
			prevState.audioRef.load()
			prevState.audioRef.play()
			return { ...prevState, isPaused: false, currentMusic: action.i }
	}
}
// G-VARIABLES FOR AUDIO CONTEXT AND ANALIZER
let audioCtx, audioAnalizer, audioSource, audioDestination
//
//
function LandingPage() {
	const [state, dispatch] = useReducer(reducer, initState)
	const audioRef = useRef(null)
	const timeRef = useRef(null)

	const [isLoading, isLoadingSET] = useState(false)

	const [previewFilesMissing, previewFilesMissingSET] = useState(true);

	// Google Statistcs
	useEffect(() => {
		ReactGA.initialize([
			{
				trackingId: GA_TRACKING_ID,
				testMode: true,
			},
		])
	}, [])
	// Initialize state on load: musics, musicLinkData.
	useEffect(() => {
		getMusicLink()
	}, [])
	// Updates the displayed time
	useEffect(() => {
		if(audioRef.current) {
			audioRef.current.ontimeupdate = (e) => {
				timeRef.current.innerText = formatTime(e.target.currentTime)
				audioRef.current.onloadedmetadata = (e) => {}
			}
		}
	})
	const getMusicLink = async () => {
		isLoadingSET(true);
		const [, , urlpart1, urlpart2] = window.location.pathname.split("/");
		const domain = window.location.origin;
		try {
			const result = await Axios.get(
				`${API_URL}musiclink-landingpage/?url_part1=${urlpart1}&url_part2=${urlpart2}&domain=${domain}`
			);
			isLoadingSET(false);
			if (result.status === 200) {
				const musicLinkData = result.data;
				getDurations(musicLinkData.preview_files);
				previewFilesMissingSET(musicLinkData.preview_files_missing);
				dispatch({ type: "setData", data: { musicLink: musicLinkData } });
				ReactGA.send({ hitType: "pageview", page: window.location.href });
			} else {
				window.location.assign('/l/notFound');
			}
		} catch (error) {
			window.location.assign('/l/notFound');
		}
	}
	//
	const getDurations = async (previewFiles) => {
		const res = await Promise.all(
			previewFiles.map(({ url }) => fetchDuration(url))
		)
		dispatch({
			type: "setData",
			data: {
				musics: (() => previewFiles.map((m, i) => ({ ...m, length: res[i] })))(),
			},
		})
	}
	//
	const onPlayClick = (event) => {
		if (state.musics.length > 0) {
			dispatch({
				type: "initialize",
				data: { currentMusic: 0, isPlayed: true, audioRef: audioRef.current },
			})
			let track_name = state.musics[0].track_name
			let music_link = state.musics[0].musiclink_id

			ReactGA.event("play_track", {
				category: "Track Played",
				action: "User clicked track play button",
				label: "Landing Page",
				track_name: track_name,
				music_link: music_link,
			})
		}
		audioCtx = new (window.AudioContext || window.webkitAudioContext)()
		audioAnalizer = audioCtx.createAnalyser()
		audioSource = audioCtx.createMediaElementSource(audioRef.current)
		audioDestination = audioCtx.destination
		// Connecting the nodes
		audioSource.connect(audioAnalizer)
		audioAnalizer.connect(audioDestination)
		event.target.disabled = true
		// Creates new audio context + analyzer
	}
	//
	const onCntrlNext = () => {
		if (state.currentMusic < state.musics.length - 1) {
			dispatch({ type: "changeSong", direction: 1 })
		}
	}
	const onCntrlPrev = () => {
		if (state.currentMusic > 0) {
			dispatch({ type: "changeSong", direction: -1 })
		}
	}
	//
	const onPlayPauseClick = () => {
		if (state.isPaused) {
			let track_name = state.musics[state.currentMusic].track_name
			let music_link = state.musics[state.currentMusic].musiclink_id

			ReactGA.event("play_track", {
				category: "Track Played",
				action: "User clicked track play button",
				label: "Landing Page",
				track_name: track_name,
				music_link: music_link,
			})
		}
		dispatch({ type: "playPause" })
	}
	//
	const onButtonLink = (link, store, musicLinkID) => () => {
		ReactGA.event("store_visit", {
			category: "Store Visit",
			action: "User clicked store play button",
			label: "Landing Page",
			store_name: store,
			link_url: link,
			music_link: musicLinkID,
		})
		window.open(link, "_blank");
	}
	//
	const onEndHandler = () => {
		if (state.currentMusic === state.musics.length - 1) {
			dispatch({ type: "setData", data: { isPaused: true, currentMusic: 0 } })
		} else {
			onCntrlNext()
		}
	}


	let headerBgStyle = {background: state.musicLink.header_color};
	let triangleIconColor = state.musicLink.header_color;

    if(state.musicLink.header_color_scheme === 2 && state.musicLink.header_gradient) {
        let headerGradientDirection = gradientDegreeDict[state.musicLink.header_gradient.direction];

        headerBgStyle = {
            "background": state.musicLink.header_gradient.first_color,
            "background": `-moz-linear-gradient(${headerGradientDirection}, ${state.musicLink.header_gradient.first_color}, ${state.musicLink.header_gradient.second_color})`,
            "background": `-webkit-linear-gradient(${headerGradientDirection}, ${state.musicLink.header_gradient.first_color}, ${state.musicLink.header_gradient.second_color})`,
            "background": `linear-gradient(${headerGradientDirection}, ${state.musicLink.header_gradient.first_color}, ${state.musicLink.header_gradient.second_color}`,
            "filter": `progid:DXImageTransform.Microsoft.gradient(startColorstr="${state.musicLink.header_gradient.first_color}",endColorstr="${state.musicLink.header_gradient.second_color}",GradientType=1)`
        };
        if(headerGradientDirection === "0deg") {
            triangleIconColor = state.musicLink.header_gradient.first_color;
        }
        else if(headerGradientDirection === "180deg") {
            triangleIconColor = state.musicLink.header_gradient.second_color;
        }
        else {
            triangleIconColor = calculateMiddleColor(state.musicLink.header_gradient.first_color, state.musicLink.header_gradient.second_color, headerGradientDirection);
        }
    }

	//
	return (
		<div className='music-landing-page'>
			<MetaTags>
				<title>Music Link | Smart Links for Music Marketing By Move Music</title>
				<meta property="og:type" content="website" />
				<meta property="og:url" content={`${window.location.href}`} />
				<meta property="og:title" content="Music Link | Smart Links for Music Marketing By Move Music" />
				<meta name="description" content="Enhance your online music presence with Music Link. Create comprehensive smart links to gather all your music, social media, and promotional content in one place. Utilize advanced analytics to understand your audience better and streamline your music marketing strategies. Join the new era of music promotion with Music Link." />
				<meta name="keywords" content="music link, move music, smart links, music marketing, online music promotion, music analytics, musicians, music artists, track insights, music distribution, music portfolio, engage audience, music promotion" />
			</MetaTags>
			<div
				className='back-image'
				style={{
					backgroundImage: state.musicLink?.image_big? `url('${state.musicLink?.image_big}')` : '',
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundAttachment: "fixed",
				}}
			/>
			<PreviewLoader show={isLoading} />
			{
				!isLoading &&
				<main className='bodymain'>
					<div className='container mb-md-5 p-0'>
						<div className={previewFilesMissing? 'row no-gutters center-div': 'row no-gutters'}>
							{
								!previewFilesMissing &&
								<div className='col-md-6 '>
									<figure className='playermain'>
										<div className='overlay' id='canvas-parent' />
										<img
											src={state.musicLink?.image_big}
											className='img-fluid audiobg'
											alt=''
											style={{
												...(state.isPlayed && {
													borderBottomLeftRadius: "0px",
													borderBottomRightRadius: "0px",
												}),
											}}
										/>

										{state.isPlayed === false && (
											<center>
												<div className='buttons'>
													<PlayIcon fillColorOne={state.musicLink.player_controls_color} fillColorTwo={"#ffffff"} onClick={onPlayClick} className="playicon" />
												</div>
											</center>
										)}

										{state.isPlayed && (
											<center>
												<div className='buttons'>
													<ControlPreviousIcon fillColor={state.musicLink.player_controls_color} onClick={onCntrlPrev} className="cleft" />
													{state.isPaused && (
														<ControlPlayIcon fillColor={state.musicLink.player_controls_color} strokeColor={state.musicLink.player_controls_color} onClick={onPlayPauseClick} className="playicon" />
													)}
													{state.isPaused === false && (
														<ControlPauseIcon fillColor={state.musicLink.player_controls_color} strokeColor={state.musicLink.player_controls_color} onClick={onPlayPauseClick} className="playicon" />
													)}
													<ControlNextIcon fillColor={state.musicLink.player_controls_color} onClick={onCntrlNext} className="cright" />
												</div>
											</center>
										)}

										<div className='time-container' style={{color: state.musicLink.player_controls_color}} ref={timeRef} />

										<div
											id='audio-canvas'
											style={{
												position: "absolute",
												bottom: "-6px",
												width: "100%",
											}}
										>
											<audio ref={audioRef} crossOrigin='' onEnded={onEndHandler}>
												<source
													crossOrigin=''
													type='audio/mp3'
													src={state.musics[state.currentMusic]?.url}
												></source>
											</audio>
										</div>
										{state.isPlayed && !state.isPaused ? (
											<AudioVisualizer
												audioAnalizer={audioAnalizer}
												canvasParenyId='canvas-parent'
											></AudioVisualizer>
										) : null}
									</figure>
									{state.isPlayed && (
										<div className='tablescroll'>
											<table className='table tracktable'>
												<thead>
													<tr>
														<th>Track</th>
														<th>Artist</th>
														<th>Time</th>
													</tr>
												</thead>
												<tbody>
													{state.musics.map((m, i) => {
														return (
															<tr
																i={i}
																className={`music-list-item ${
																	state.currentMusic === i ? "music-list-item-active" : ""
																}`}
																onClick={() => {
																	dispatch({ type: "itemSelect", i: i })
																}}
															>
																<td style={{color: state.currentMusic === i ? state.musicLink.player_controls_color: "#ffffff"}}>{m.artist_name}</td>
																<td style={{color: state.currentMusic === i ? state.musicLink.player_controls_color: "#ffffff"}}>{m.track_name}</td>
																<td style={{color: state.currentMusic === i ? state.musicLink.player_controls_color: "#ffffff"}}>{m?.length}</td>
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
									)}
								</div>
							}
							<div className='col-md-6'>
								<div className='card logocard'>
									<div className='card-header pb-3' style={{...headerBgStyle,}}>
										<h2 style={{color: state.musicLink.title_color?state.musicLink.title_color: "#ffffff"}}>{state.musicLink?.release_name}</h2>
										<p style={{color: state.musicLink.description_color?state.musicLink.description_color: "#ffffff"}}>{state.musicLink?.artist_name}</p>
										<p style={{color: state.musicLink.description_color?state.musicLink.description_color: "#ffffff"}} className='choose-music-text mt-2'>Choose Music Service</p>
										<TriangleIcon fillColor={triangleIconColor} className="triangle" />
									</div>
									<div className='card-body'>
										<ul className='logolist row row-cols-2 m-0'>
											{state.musicLink?.amazonmusic_url && (
												<li>
													<div className='brandlogo'>
														<img src={AmazonMusicSvg} alt='' height={'100%'} width={110} />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.amazonmusic_url}
														storeName={"amazonmusic"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.anghami_url && (
												<li>
													<div className='brandlogo'>
														<img src={Anghami} alt='' height={'100%'} width={110} />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.anghami_url}
														storeName={"anghami"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.applemusic_url && (
												<li>
													<div className='brandlogo'>
														<img src={Am} alt='' />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.applemusic_url}
														storeName={"applemusic"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.bandcamp_url && (
												<li>
													<div className='brandlogo'>
														<img src={Bandcamp} alt='' height={'100%'} width={110} />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.bandcamp_url}
														storeName={"bandcamp"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.beatport_url && (
												<li>
													<div className='brandlogo'>
														<img src={Beatport} alt='' />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.beatport_url}
														storeName={"beatport"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.boomplay_url && (
												<li>
													<div className='brandlogo'>
														<img src={Boomplay} alt='' height={'100%'} width={110} />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.boomplay_url}
														storeName={"boomplay"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.deezer_url && (
												<li>
													<div className='brandlogo'>
														<img src={DeezerSvg} alt='' />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.deezer_url}
														storeName={"deezer"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.iheartradio_url && (
												<li>
													<div className='brandlogo'>
														<img src={iHeartRadio} alt='' height={'100%'} width={110} />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.iheartradio_url}
														storeName={"iheartradio"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.jiosaavn_url && (
												<li>
													<div className='brandlogo'>
														<img src={JioSaavn} alt='' height={'100%'} width={110} />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.jiosaavn_url}
														storeName={"jiosaavn"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.junodownload_url && (
												<li>
													<div className='brandlogo'>
														<img src={Juno} alt='' />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.junodownload_url}
														storeName={"junodownload"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.sevendigital_url && (
												<li>
													<div className='brandlogo'>
														<img src={SevenDigital} alt='' height={'100%'} width={110} />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.sevendigital_url}
														storeName={"sevendigital"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.soundcloud_url && (
												<li>
													<div className='brandlogo'>
														<img src={ScSvg} alt='' />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.soundcloud_url}
														storeName={"soundcloud"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.spotify_url && (
												<li>
													<div className='brandlogo'>
														<img src={Spotify} alt='' />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.spotify_url}
														storeName={"spotify"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.tidal_url && (
												<li>
													<div className='brandlogo'>
														<img src={Tidal} alt='' />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.tidal_url}
														storeName={"tidal"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.traxsource_url && (
												<li>
													<div className='brandlogo'>
														<img src={TraxsourceSvg} alt='' />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.traxsource_url}
														storeName={"traxsource"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.youtube_url && (
												<li>
													<div className='brandlogo'>
														<img src={Yt} alt='' />
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.youtube_url}
														storeName={"youtube"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
											{state.musicLink?.other_url && (
												<li>
													<div className='brandlogo'>
														<div className='empty'>Others</div>
													</div>
													<StoreLinkButton
														storeLink={state.musicLink?.other_url}
														storeName={"other"}
														musicLinkData={state.musicLink}
														onButtonLink={onButtonLink}
													/>
												</li>
											)}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='text-center copyright text-white'>
						<div>
							We use essential cookies to provide service. To manage your permissions click the icon in the lower left corner.
						</div>
					</div>
				</main>
			}
		</div>
	)
}

export default LandingPage;